.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: Futurapt, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

p {
  margin-bottom: 0px;
}

label {
  display: block;
  margin-bottom: 0px;
  font-weight: 700;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 90vh;
  padding-bottom: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  background-image: url('../images/particles-bright.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.section.player {
  min-height: 90vh;
  padding-bottom: 0rem;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #000;
  background-image: none;
}

.section.player.postcard {
  display: none;
}

.section.menu {
  padding-bottom: 2rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-image: none;
  background-size: auto;
  background-repeat: repeat;
}

.section.postcard {
  padding-bottom: 4rem;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.section.no-bg {
  overflow: visible;
  min-height: auto;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-image: none;
  background-size: auto;
}

.h1 {
  color: #fff;
  font-size: 3rem;
  line-height: 1;
  font-weight: 600;
}

.h1.centered {
  margin-bottom: 2rem;
  text-align: center;
}

.h1.hero {
  position: relative;
  left: -0.5rem;
  margin-bottom: 2rem;
  font-size: 10vw;
  text-align: center;
}

.h1.margin-bottom-3 {
  margin-bottom: 3rem;
}

.h2 {
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 400;
}

.h2.centered {
  text-align: center;
}

.h2.centered.bold {
  font-weight: 600;
}

.h2.centered.bold.button {
  line-height: 1.5;
}

.h2.centered.black {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #000;
}

.h2.max-width {
  max-width: 40rem;
  margin-bottom: 2rem;
}

.h2.nav {
  font-size: 1rem;
  font-weight: 600;
}

.h2.nav.right {
  margin-right: 0rem;
  text-align: right;
}

.h2.nav.highlighted {
  color: #000;
}

.h2.black {
  color: #000;
}

.h2.bold {
  font-weight: 600;
}

.h2.bold.postcard-text {
  max-width: 700px;
}

.h2.pp {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.max-width-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.headline-wrap {
  margin: 0rem 2rem 2rem;
}

.illustration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.code-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid rgba(221, 243, 255, 0.2);
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(51%, rgba(167, 223, 255, 0.1)), to(transparent));
  background-image: linear-gradient(180deg, transparent, rgba(167, 223, 255, 0.1) 51%, transparent);
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-transform: perspective(235px) perspective(500px);
  transform: perspective(235px) perspective(500px);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.code-wrap._2 {
  top: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(51%, rgba(167, 223, 255, 0.1)), to(transparent));
  background-image: linear-gradient(180deg, transparent, rgba(167, 223, 255, 0.1) 51%, transparent);
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.code-wrap._2.preloadd {
  background-image: none;
}

.code-wrap._3 {
  -webkit-transform: perspective(200px) perspective(500px);
  transform: perspective(200px) perspective(500px);
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.code-wrap._3.preload {
  -webkit-transform: perspective(235px) rotateX(0deg) rotateY(40deg) rotateZ(0deg) perspective(500px);
  transform: perspective(235px) rotateX(0deg) rotateY(40deg) rotateZ(0deg) perspective(500px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.code-wrap.preload {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transform: perspective(235px) rotateX(0deg) rotateY(-40deg) rotateZ(0deg) perspective(500px);
  transform: perspective(235px) rotateX(0deg) rotateY(-40deg) rotateZ(0deg) perspective(500px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.code {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p {
  color: #a7dfff;
  font-size: 1rem;
  line-height: 1;
  white-space: pre;
}

.p.hero {
  font-family: 'IBM Plex Mono', sans-serif;
  color: #fff;
  font-size: 2rem;
  text-align: right;
}

.sound-piece {
  display: inline-block;
  width: 0px;
  height: 1rem;
  margin-right: 4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 6px solid #fff;
  border-radius: 100px;
}

.sound-piece._12x {
  height: 1.2rem;
}

.sound-piece._14x {
  height: 1.4rem;
}

.sound-piece._16x {
  height: 1.6rem;
}

.sound-piece._20x {
  height: 2rem;
}

.sound-piece._30x {
  height: 3rem;
}

.soundwave-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.5rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  padding: 1rem 4rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.header.player {
  height: 10vh;
}

.logo {
  height: 30px;
}

.about-link {
  -webkit-transition: color 200ms ease-in-out;
  transition: color 200ms ease-in-out;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  text-decoration: none;
}

.about-link:hover {
  color: #a7dfff;
}

.button-hero {
  padding: 0.9rem 3rem 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 12px;
  background-color: #fff;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #131313;
  font-size: 1.5rem;
  font-weight: 600;
}

.button-hero:hover {
  background-color: transparent;
  color: #fff;
}

.button-hero.player {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  text-align: center;
}

.button-hero.player.secondary {
  background-color: transparent;
  color: #fff;
}

.caption-hero {
  margin-right: 3rem;
  margin-bottom: 1rem;
  color: rgba(221, 243, 255, 0.5);
  font-size: 0.75rem;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

.caption-hero.center {
  margin-right: 0rem;
  text-align: center;
}

.caption-hero.center.right {
  text-align: left;
}

.caption-hero.left {
  margin-right: 0rem;
  margin-left: 3rem;
  text-align: left;
}

.player-wrap {
  min-width: 200px;
  margin-bottom: 4rem;
  padding-top: 1.5rem;
}

.playback-tracker {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(221, 243, 255, 0.2);
}

.playback-amount {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  width: 30%;
  height: 100%;
  border-radius: 4px;
  background-color: #fff;
}

.buttons {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-player {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #fff;
}

.icon-player:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.icon-player.forward {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0rem;
  margin-left: 0rem;
}

.icon-player.forward.invis {
  opacity: 0;
}

.icon-player.forward.sound-on {
  display: none;
}

.icon-player.forward.postcard-share {
  color: #000;
}

.icon-player.pause {
  display: block;
  margin-right: 0rem;
  margin-left: 0rem;
}

.icon-player.play {
  display: none;
  margin-right: 0rem;
  margin-left: 0rem;
}

.track-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-block {
  margin-top: 0.5rem;
  color: #fff;
  font-weight: 600;
  text-align: right;
}

.main-player-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 2rem;
  margin-left: 2rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lower-div {
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding-right: 4rem;
  padding-left: 4rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-auto-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lower-div.postcard {
  padding-left: 0rem;
}

.gift {
  width: 2rem;
  margin-bottom: 1rem;
  color: #fff;
}

.custom-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 220px;
  margin-bottom: 4rem;
  padding: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 4px;
  -webkit-transition: all 200ms cubic-bezier(.455, .03, .515, .955);
  transition: all 200ms cubic-bezier(.455, .03, .515, .955);
  text-decoration: none;
}

.custom-button:hover {
  -webkit-transform: scale(1.1) rotate(5deg);
  -ms-transform: scale(1.1) rotate(5deg);
  transform: scale(1.1) rotate(5deg);
}

.custom-button.share {
  height: auto;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-style: none;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.paragraph {
  padding-top: 0.75rem;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.4;
  text-align: right;
}

.paragraph.no-border {
  padding-bottom: 0rem;
  border-bottom-style: none;
}

.paragraph.centered {
  text-align: center;
}

.paragraph.left {
  text-align: left;
}

.nav {
  position: absolute;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.nav.right {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin-right: 4rem;
  margin-left: 0rem;
}

.arrow-nav {
  width: 1.5rem;
  margin-top: 10px;
  margin-right: 1rem;
}

.arrow-nav.right {
  margin-right: 0rem;
  margin-left: 1rem;
}

.menu-heading-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem 4rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-4 {
  margin-right: 4rem;
  margin-bottom: 1rem;
  margin-left: 4rem;
  grid-column-gap: 2rem;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 80vh;
  min-height: 50vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 16px;
  -webkit-transform: perspective(549px);
  transform: perspective(549px);
}

.card.postcard {
  height: auto;
  min-width: 30vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: none;
}

.card.ready-postcard {
  min-height: auto;
  padding: 2rem 3rem 2rem 4rem;
  border-bottom-style: none;
  border-left-style: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.button-card {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.9rem 1rem 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 12px;
  background-color: #fff;
  -webkit-transition: background-color 100ms ease-in-out;
  transition: background-color 100ms ease-in-out;
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.button-card:hover {
  background-color: transparent;
  color: #fff;
}

.button-card.postcard {
  min-width: 300px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 12px;
}

.button-card.postcard.small {
  min-width: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.button-card.postcard.ready {
  margin-right: 0rem;
  margin-left: 0rem;
}

.vis-thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card-heading-wrap {
  padding: 2rem 1rem;
  border-bottom: 1px solid #fff;
}

.share-block {
  margin-top: 1rem;
  padding: 1rem 2rem 2rem;
  border-radius: 16px;
  background-color: #fff;
}

.share-block.postcard {
  margin: 0rem 2rem 2rem;
}

.share-menu-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block {
  width: 1rem;
  height: 1rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.close-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: rgba(0, 0, 0, 0.3);
}

.close-icon.abs {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 1rem;
  margin-right: 1rem;
  color: rgba(221, 243, 255, 0.2);
}

.share-social {
  display: -ms-grid;
  display: grid;
  margin-top: 1rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.h3 {
  color: #000;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
}

.h3.copy {
  display: none;
}

.social-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 1rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 4px;
}

.social-block:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.share-block-wrap {
  overflow: hidden;
}

.header-wrap {
  width: 100%;
}

.menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 0rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 100%;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
}

.menu-button:hover {
  background-color: rgba(221, 243, 255, 0.2);
}

.menu-button.highlighted {
  background-color: #fff;
  color: #000;
}

.html-embed {
  position: relative;
  top: 1px;
  width: 1.4rem;
  color: #fff;
}

.arrowed-link {
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1rem;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.form-wrap {
  margin-bottom: 1rem;
}

.postcard-wrap {
  max-height: 80vh;
  min-height: 30vh;
  min-width: 400px;
  padding: 1rem 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.label {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.label.ready {
  margin-bottom: 0.5rem;
  color: #fff;
}

.field {
  margin-bottom: 0px;
  padding: 1.5rem 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 12px;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
}

.field:hover {
  background-color: rgba(221, 243, 255, 0.1);
}

.field:focus {
  border-color: #a7dfff;
}

.field.greeting {
  height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.2;
}

.body {
  background-color: #000;
}

.link {
  color: #fff;
}

.img-vis {
  -webkit-filter: brightness(500%);
  filter: brightness(500%);
  -o-object-fit: contain;
  object-fit: contain;
}

.icon-player-sound-wrap {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-player-playpause-wrap {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.spacer {
  display: none;
  min-height: 30vh;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.postcard-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.postmark-wrap {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80vw;
  min-width: 50vw;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 16px;
}

.postmark {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10rem;
  height: 5rem;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-postcard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 1px none #fff;
}

.img-vis-postmark {
  overflow: hidden;
  height: 5rem;
  border-style: dashed;
  border-width: 1px;
  border-color: rgba(221, 243, 255, 0.2);
  -webkit-filter: brightness(600%);
  filter: brightness(600%);
}

.grid-2 {
  display: -ms-grid;
  display: grid;
  padding-top: 2rem;
  padding-bottom: 2rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-4 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.9rem 1rem 1rem;
  border-radius: 12px;
  background-color: #fff;
}

.h1-wrap {
  margin-right: 4rem;
  margin-left: 4rem;
}

.preloader {
  position: absolute;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 300px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(221, 243, 255, 0.2);
}

.particles {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.lead-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.big-link {
  color: #fff;
  font-size: 1rem;
  text-decoration: underline;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 4rem;
  padding-left: 4rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-container {
  max-width: 40rem;
}

.paragraph-normal {
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  line-height: 1.4;
}

.text-span {
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-loader {
  font-family: 'IBM Plex Mono', sans-serif;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
}

.menu-icon {
  display: none;
}

.html-embed-2 {
  width: 2rem;
  height: 2rem;
  color: #fff;
}

.copy-wrap {
  height: 1.2rem;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .h2.bold.postcard-text {
    margin-top: 2rem;
  }

  .header {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .player-wrap {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lower-div {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .custom-button {
    width: 100%;
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .custom-button:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .footer {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .paragraph {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    text-align: center;
  }

  .menu-heading-wrapper {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .grid-4 {
    margin-right: 2rem;
    margin-left: 2rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .card {
    height: auto;
  }

  .card.ready-postcard {
    padding-left: 2rem;
    border-bottom-style: solid;
    border-bottom-right-radius: 16px;
  }

  .button-card {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .button-card.postcard {
    min-width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .card-heading-wrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .menu-button.highlighted:hover {
    background-color: #fff;
  }

  .img-vis {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .post {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card-postcard {
    border-left-style: none;
  }

  .grid-2 {
    padding-bottom: 2rem;
  }

  .div-block-4 {
    margin-bottom: 0.5rem;
  }

  .div-block-6 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .text-container {
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .section.no-bg {
    display: block;
  }

  .h1 {
    font-size: 2.5rem;
  }

  .h1.centered {
    margin-bottom: 2rem;
  }

  .header {
    height: 10vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .logo {
    height: 20px;
  }

  .about-link {
    font-size: 1.25rem;
  }

  .lower-div {
    margin-top: 40vh;
    padding-right: 2rem;
    padding-left: 2rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .nav {
    position: static;
    height: 5vh;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .vis-thumbnail {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .html-embed {
    width: 1.25rem;
  }

  .arrowed-link {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .spacer {
    display: block;
  }

  .post {
    max-width: none;
    min-width: auto;
    margin-right: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card-postcard {
    border-left-style: none;
  }

  .grid-2 {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 479px) {
  .section.player {
    padding-bottom: 2rem;
  }

  .section.no-bg {
    overflow: visible;
  }

  .h1.hero {
    font-size: 12vw;
  }

  .header {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .about-link {
    text-align: center;
  }

  .player-wrap {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .lower-div {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .nav {
    padding-left: 0.5rem;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .menu-heading-wrapper {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .grid-4 {
    margin-right: 1rem;
    margin-left: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .card.ready-postcard {
    padding-left: 1rem;
  }

  .close-icon.menu {
    color: #fff;
  }

  .html-embed {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .arrowed-link {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .postcard-wrap {
    max-height: none;
    min-height: auto;
    min-width: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .post {
    margin-right: 1rem;
    margin-left: 1rem;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .div-block-6 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .menu-wrap {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 9999999;
    display: none;
    width: 100%;
    padding: 2rem 1rem 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #000;
  }

  .text-container {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .menu-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .close-menu {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 1.5rem;
    margin-right: 1rem;
  }
}

#w-node-8451ddeaedee-3fee9a7e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ba61879d6bce-3fee9a7e {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e75dbbdf2bf0-3fee9a7e {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-8451ddeaedee-61e5d706 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c728342aa1ca-61e5d706 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e75dbbdf2bf0-61e5d706 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-8451ddeaedee-e1bd8592 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f4ad4b164ae8-e1bd8592 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e75dbbdf2bf0-e1bd8592 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-8451ddeaedee-eea86d28 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-5ed1ec912b5f-eea86d28 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e75dbbdf2bf0-eea86d28 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-ef9bbfc6670d-eea86d28 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ef9bbfc66730-eea86d28 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ef9bbfc66736-eea86d28 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-8451ddeaedee-fa42b450 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-fcb6d4fa983c-fa42b450 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e75dbbdf2bf0-fa42b450 {
  -ms-grid-column-align: end;
  justify-self: end;
}

@media screen and (max-width: 991px) {
  #w-node-8451ddeaedee-3fee9a7e {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-3fee9a7e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-8451ddeaedee-61e5d706 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-61e5d706 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-8451ddeaedee-e1bd8592 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-e1bd8592 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-8451ddeaedee-eea86d28 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-eea86d28 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ef9bbfc666fc-eea86d28 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-ef9bbfc6670d-eea86d28 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-ef9bbfc66736-eea86d28 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-bb3d514d87d2-fa42b450 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-8451ddeaedee-fa42b450 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-fa42b450 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media screen and (max-width: 767px) {
  #w-node-9f58f2b8bad1-3fee9a7e {
    -webkit-box-ordinal-group: 1000;
    -webkit-order: 999;
    -ms-flex-order: 999;
    order: 999;
  }

  #w-node-8451ddeaedee-3fee9a7e {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-3fee9a7e {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-9f58f2b8bad1-61e5d706 {
    -webkit-box-ordinal-group: 1000;
    -webkit-order: 999;
    -ms-flex-order: 999;
    order: 999;
  }

  #w-node-8451ddeaedee-61e5d706 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-61e5d706 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-9f58f2b8bad1-e1bd8592 {
    -webkit-box-ordinal-group: 1000;
    -webkit-order: 999;
    -ms-flex-order: 999;
    order: 999;
  }

  #w-node-8451ddeaedee-e1bd8592 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-e1bd8592 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-9f58f2b8bad1-eea86d28 {
    -webkit-box-ordinal-group: 1000;
    -webkit-order: 999;
    -ms-flex-order: 999;
    order: 999;
  }

  #w-node-8451ddeaedee-eea86d28 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-eea86d28 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-ef9bbfc6670d-eea86d28 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-ef9bbfc66736-eea86d28 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-8451ddeaedee-fa42b450 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e75dbbdf2bf0-fa42b450 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
}

@media screen and (max-width: 479px) {
  #w-node-ef9bbfc666fc-eea86d28 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-bb3d514d87d2-fa42b450 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
}

@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-BookObl.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-BoldObl.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-BoldObl.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futuraptcond';
  src: url('../fonts/FuturaPTCond-MediumObl.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-LightObl.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-HeavyObl.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-DemiObl.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-ExtraBoldObl.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-BookObl.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futurapt';
  src: url('../fonts/FuturaPT-MediumObl.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}